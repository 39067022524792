.multi-email-wrapper {
  width: 100%;
  cursor: text;

  & [data-tag="true"] {
    background: transparent !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 1px solid #bcbcc3 !important;
    border-radius: 30px !important;
    padding: 4px 10px !important;
  }

  .react-multi-email {
    > input {
      width: 95% !important;
    }

    & [data-tag="true"] + input {
      width: 25% !important;
    }
  }
}

.email-subject-wrapper {
  width: 100%;

  & fieldset {
    border: none;
  }

  & .MuiOutlinedInput-root {
    height: 25px;
  }
}

.email-quill-wrapper {
  padding-top: 10px;
  width: 100%;
  height: 140px;
  & .ql-container.ql-snow,
  & .ql-toolbar.ql-snow {
    border: none;
  }

  & .ql-toolbar.ql-snow {
    display: none;
  }

  & .ql-editor {
    padding: 0;
    height: 120px;
    width: 100%;
    font-size: 15px;
    overflow-y: auto;
    overflow-wrap: anywhere;
    background-color: white !important;

    & * {
      color: #000000 !important;
      background-color: white !important;
      font-family: "Heebo", sans-serif !important;
    }

    & a {
      color: #2483f7 !important;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  & .ql-editor.ql-blank::before {
    font-style: normal;
    left: 0;
    right: 0;
    color: #7a7a86;
  }
}
