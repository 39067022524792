.new-message-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  & [data-tag='true'] {
    background: transparent !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 1px solid #bcbcc3 !important;
    border-radius: 30px !important;
    padding: 4px 10px !important;
  }

  .react-multi-email {
    > input {
      width: 95% !important;
    }

    & [data-tag='true'] + input {
      display: none !important;
    }
  }
}
