.code-input__wrapper {
  & .react-code-input {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  & input {
    font-family: 'system-ui' !important;
    box-shadow: none !important;
    width: 50px !important;
    height: 70px !important;
    text-align: center !important;
    padding-left: 0 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    border-color: #bcbcc3 !important;

    &:active,
    &:focus,
    &:focus-visible,
    &:target {
      border-color: red !important;
    }

    @media (max-width: 576px) {
      width: 35px !important;
      height: 50px !important;
    }
  }
}
