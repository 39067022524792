.modal-library--searcher {

  .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 17px;
    background: #f8f9fb;
  }

  & fieldset {
    border: none;
  }
}
