.billing-form {
  & .card-number-element {
    border: 1px solid rgba(188, 188, 195, 0.6);
    border-radius: 6px;
    padding: 10px 15px;
    margin-top: 6px;

    &:hover {
      border-color: black;
    }
  }
}