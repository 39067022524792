div[class*='post--reactions'] {
  height: 100%;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;

  & .reaction-btn {
    height: 20px;
    box-shadow: none;
    width: 20px;
    z-index: 99;

    &:hover {
      background-color: transparent;
    }
  }
}
.epr-main {
  z-index: 9999999;
  .epr-header {
    .epr-category-nav {
      display: none;
    }

    .epr-search-container {
      input.epr-search {
        border-radius: 20px;
        background-color: #f8f9fb;
      }
    }
  }

  .epr-body {
    .epr-emoji-list {
      .epr-emoji-category {
        .epr-emoji-category-label {
          font-size: 15px;
          margin-left: 8px;
        }
      }

      .epr-emoji-category-content {
        font-size: 10px;

        .epr-btn {
          img {
            max-height: 35px !important;
          }
        }
      }
    }
  }
}

.single--emoji {
  font-size: 40px;
}

.post__layout {
  #menu-button {
    visibility: hidden;
  }

  &:hover {
    #menu-button {
      visibility: visible;
    }
  }
}

.post__action__menu {
  div:has(ul) {
    border-radius: 10px;
    width: fit-content;
    max-width: 200px;
  }

  li {
    font-size: 15px;

    .delete-option {
      color: red;
    }
  }
}
