.page-not-found-container {
  display: grid;
  grid-template-columns: 1fr;
  height: 500px;

  div {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .layer1 {
    padding-top: 40px;
  }

  .layer2 {
    height: 500px;
  }
}
