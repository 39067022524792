.contacts__header__wrapper {
  position: fixed;
  top:0;
  padding: 20px;
  background-color: #fff;
  z-index: 100;
  transition: top 0.6s;


  & .MuiOutlinedInput-root {
    border-radius: 17px;
    background: #f8f9fb;
  }

  & fieldset {
    border: none;
  }
}
