.video-js .vjs-big-play-button {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 55px;
  border-radius: 50%;
  border-width: 0;

  background: rgba(36, 131, 247, 0.5);
  backdrop-filter: blur(11px);

  left: 50%;
  top: 50%;
}

.video-js {
  margin-bottom: 10px;
  width: 100%;
  max-width: 100%;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 14px;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  max-height: calc(100vh - 100px);
  position: relative !important;
  width: 100%;
  height: auto !important;
  max-width: fit-content;
  padding-top: 0 !important;
  border-radius: 10px;
}

.video-js.vjs-fullscreen {
  display: flex;
  align-items: center;

  video {
    position: relative !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
  }
}

.video-js-ready * {
  visibility: visible;
}

.video-js-hidden * {
  visibility: hidden;
}
.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  width: 50px;
  min-height: 200px;
}
