.chat__desktop {
  .video-js.vjs-fluid,
  .video-js.vjs-fluid video {
    border-radius: 10px;
    display: flex;
    max-width: 100% !important;
    align-items: center;
  }

  .video-js.vjs-fullscreen video {
    border-radius: 0;
    max-width: 100% !important;
    width: 100% !important;
  }

  .chat__desktop-player-title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    padding: 15px 20px 40px 20px;
  }

  .chat__desktop-player-title-backdrop-top {
    width: 100% !important;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    opacity: 0.8;
    border-radius: 10px;
  }

  .chat__desktop-player-title-backdrop-bottom {
    width: 100% !important;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    opacity: 0.8;
    border-radius: 10px;
  }

  .vjs-poster {
    border-radius: 10px;
  }

  .c-player {
    border-radius: 10px;
  }

  .video-js.vjs-fullscreen video {
    height: auto !important;
  }

  position: relative;
  .video-js .vjs-big-play-button {
    //display: none;
    //width: 50px;
    //height: 50px;
    //line-height: 50px;
    //font-size: 20px;
    //position: absolute;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
  }

  .vjs-control-bar {
    z-index: 10;

    display: flex;
    align-items: flex-end;
    height: 60px;
    background: linear-gradient(360deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vjs-progress-control {
    z-index: 10;
    top: 15px;
    left: 0;
    height: 10px;
    width: 100%;
    position: absolute;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 26px;
  }
  .video-js .vjs-slider {
    height: 4px;
  }

  //.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar

  .video-js .vjs-volume-panel {
    z-index: 11;
    position: absolute;
    left: 40px;
    height: 40px;
  }

  .vjs-volume-horizontal {
    height: 30px;
    margin-top: 5px;
  }

  .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
  .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
    z-index: 9999;
    width: 75px;
  }

  .video-js .vjs-time-control {
    z-index: 10;
    position: absolute;
    right: 165px;
    height: 40px;
    font-size: 14px;
  }

  .vjs-playback-rate {
    z-index: 10;
    position: absolute;
    right: 105px;
    height: 34px;
  }

  .vjs-picture-in-picture-control {
    position: absolute;
    right: 35px;
    height: 40px;
  }

  .vjs-picture-in-picture-control > .vjs-icon-placeholder:before {
    font-size: 24px;
  }

  .video-js .vjs-fullscreen-control {
    position: absolute;
    right: 0;
    height: 45px;
  }

  .vjs-fullscreen-control .vjs-icon-placeholder:before {
    font-size: 30px;
  }

  .video-js .vjs-play-progress:before {
    z-index: 101;
  }

  .video-js .vjs-play-control,
  .video-js .vjs-mute-control {
    height: 40px;
  }

  .custom-title-fade-out {
    display: flex;
    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 1.5s, opacity 1.5s;
    -moz-transition: visibility 1.5s, opacity 1.5s;
    -ms-transition: visibility 1.5s, opacity 1.5s;
    -o-transition: visibility 1.5s, opacity 1.5s;
    transition: visibility 1.5s, opacity 1.5s;

    /* Wait a moment before fading out the control bar */
    -webkit-transition-delay: 2s;
    -moz-transition-delay: 2s;
    -ms-transition-delay: 2s;
    -o-transition-delay: 2s;
    transition-delay: 2s;
  }
}

.video__post__activity {
  & .video__post__activity-activity-box {
    height: 26px;
    width: calc(100% - 28px);
    position: relative;
    margin-left: 6px;
    background: #f8f9fb;
    display: flex;
    align-items: center;

    &::after {
      position: absolute;
      top: 0;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 22px solid #f8f9fb;
      margin-left: 100%;
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 13px solid #f8f9fb;
      border-bottom: 13px solid #f8f9fb;
      border-left: 22px solid transparent;
      top: 0;
      left: -22px;
      position: absolute;
    }
  }

  & .video__post__activity-grid-item:first-child {
    & .video__post__activity-activity-box {
      height: 26px;
      width: calc(100% - 22px);
      position: relative;
      margin-left: 0;
      background: #f8f9fb;

      &::after {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 22px solid #f8f9fb;
        margin-left: 100%;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: none;
        border-bottom: none;
        border-left: none;
        top: 0;
      }
    }
  }

  & .video__post__activity-activity-box-valid {
    height: 26px;
    width: calc(100% - 28px);
    position: relative;
    margin-left: 6px;
    background: #2483f7;
    display: flex;
    align-items: center;
    color: white;

    &::after {
      position: absolute;
      top: 0;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 22px solid #2483f7;
      margin-left: 100%;
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 13px solid #2483f7;
      border-bottom: 13px solid #2483f7;
      border-left: 22px solid transparent;
      top: 0;
      left: -22px;
      position: absolute;
    }
  }

  & .video__post__activity-grid-item:first-child {
    & .video__post__activity-activity-box-valid {
      height: 26px;
      width: calc(100% - 22px);
      position: relative;
      margin-left: 0;
      background: #2483f7;

      &::after {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 22px solid #2483f7;
        margin-left: 100%;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: none;
        border-bottom: none;
        border-left: none;
        top: 0;
      }
    }
  }
}

.removed__video {
  &__container {
    max-width: calc(100% - 60px);
    display: flex;
    margin-top: 10px;
  }
  &__content {
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 7px;
    background-color: #f8f9fb;
    align-items: center;
    justify-content: center;
  }

  &__icons {
    position: relative;
    display: flex;
    padding-right: 9px;
  }

  &__icon-cross {
    position: absolute;
    top: 14px;
    left: 10px;
  }
}
