.signUp__form {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
    & fieldset {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    &:hover fieldset,
    &.Mui-focused fieldset {
      border: 1px solid #2483f7;
    }
  }
}
