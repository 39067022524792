.available__emails__list {
  max-height: 280px;
  overflow-y: auto;

  .contact-row {
    cursor: pointer;

    & p {
      font-size: 15px
    }

    &:focus, &:hover {
      background-color: #f8f9fb;
      outline-width: 0;
    }

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .contact-parts {
    & .contacts__highlight {
      color: black;
    }

    & .contacts__highlight-none {
      color: #7a7a86;
    }
  }


}

