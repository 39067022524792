.scrollable__postList {
  &::-webkit-scrollbar {
    width: 5px;
    //height: 0.5em;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 3px;
    visibility: hidden;
  }
}

.balloons-anim {
  animation: float 5s ease-in infinite;
  position: absolute;
  z-index: 100000;
  @keyframes float {
    0% {
      right: 0;
      top: 100vh;
      opacity: 1;
      width: 250px;
    }
    35% {
      transform: rotate(15deg);
      width: 450px;
    }
    70% {
      transform: rotate(-20deg);
    }
    100% {
      right: 50%;
      top: -600px;
      width: 700px;
    }
  }
}
