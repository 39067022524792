.statistics__video__player {
  width: 85%;
  position: relative;
  .vjs-big-play-button {
    width: 120px;
    height: 120px;
    line-height: 120px;
    font-size: 55px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: rgba(36, 131, 247, 0.5);
    backdrop-filter: blur(2.61905px);
  }

  .vjs-control-bar {
    display: block;
    height: 38px;
    background: linear-gradient(0deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vjs-progress-control {
    top: -8px;
    left: 0;
    height: 10px;
    width: 100%;
    position: absolute;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 26px;
  }

  .video-js .vjs-slider {
    height: 4px;
  }

  .video-js .vjs-volume-panel {
    position: absolute;
    left: 40px;
    top: 0;
  }

  .vjs-volume-horizontal {
    height: 30px;
    margin-top: 5px;
  }

  .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
  .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
    width: 75px;
  }

  .video-js .vjs-time-control {
    position: absolute;
    right: 165px;
    top: -2px;
    font-size: 14px;
  }

  .vjs-playback-rate {
    position: absolute;
    right: 105px;
    top: 4px;
  }

  .vjs-picture-in-picture-control {
    position: absolute;
    right: 35px;
    top: -2px;
  }

  .video-js .vjs-fullscreen-control {
    position: absolute;
    right: 0;
    top: -2px;
  }

  .video-js .vjs-play-progress:before {
    z-index: 101;
  }

  .video-js-ready {
    display: flex;
    justify-content: center;
  }

  .video-js-hidden * {
    visibility: hidden;
  }

  .vjs-poster {
    background-size: cover;
    background-position: center;
  }
}
