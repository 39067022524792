.customer-chat__quill-wrapper {
  position: relative;
  min-height: 50px;

  & .ql-container.ql-snow,
  & .ql-toolbar.ql-snow {
    border: none;
  }

  & .ql-toolbar.ql-snow {
    display: none;
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 160px;
  }

  & .ql-editor {
    padding-left: 9px;
    max-height: 200px;
    width: 100%;
    font-size: 15px;
    overflow-y: auto;
    overflow-wrap: anywhere;

    &:before {
      padding-left: 10px;
    }
  }

  & .ql-stroke {
    stroke: #7a7a86;
  }

  & .ql-snow.ql-toolbar button:hover {
    color: #2483f7;
  }

  & .ql-editor.ql-blank::before {
    font-style: normal;
    left: 0;
    color: #bcbcc3;
  }

  & .ql-snow .ql-tooltip {
    left: 5px !important;
    top: -5px !important;
  }

  & .ql-snow .ql-editor pre.ql-syntax {
    background: transparent !important;
    color: #171717;
  }
}

.editor--action-bar {
  & .chat__send-btn {
    box-shadow: none;
    width: 30px;
    height: 30px;

    &:hover {
      color: #2483f7;
    }
  }
}
