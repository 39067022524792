.post__layout {
  .post--action_popup {
    visibility: hidden;
  }

  &:hover {
    .post--action_popup {
      visibility: visible;
    }
  }

  div[class*='post--reactions'] {
    visibility: hidden;
  }

  &:hover {
    div[class*='post--reactions'] {
      padding-left: 10px;
      padding-top: 2px;
      visibility: visible !important;
    }
  }

  .post__actions {
    visibility: hidden;
  }

  &:hover {
    .post__actions {
      visibility: visible;
    }
  }
}
