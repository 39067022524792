.resizable__container {
  //transition: all 0.5s !important;
  height: 100vh;

  pointer-events: none;

  .arrow-button {
    pointer-events: auto;
  }

  div[style*='cursor: col-resize'] {
    pointer-events: auto;
  }
}
