.group-header__wrapper {
  align-items: center;
  &:hover {
    background: rgba(36, 131, 247, 0.05);
  }
  & p {
    user-select: none;
  }

  .recent-messages {
    box-shadow: none;
    background-color: transparent;
    z-index:999;

    &:hover {
      background-color: transparent;
    }
  }

  .direct-messages{
    display: none;
  }
}
