.chat-player__video-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

.chat-player__fade-out {
  display: flex;
  visibility: hidden;
  opacity: 0;

  -webkit-transition: visibility 1.5s, opacity 1.5s;
  -moz-transition: visibility 1.5s, opacity 1.5s;
  -ms-transition: visibility 1.5s, opacity 1.5s;
  -o-transition: visibility 1.5s, opacity 1.5s;
  transition: visibility 1.5s, opacity 1.5s;

  /* Wait a moment before fading out the control bar */
  -webkit-transition-delay: 2s;
  -moz-transition-delay: 2s;
  -ms-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}