.chat__player {
  &:hover {
    & .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      opacity: 0 !important;
    }
  }

  .video-js.vjs-fluid,
  .video-js.vjs-fluid video {
    border-radius: 10px;
    display: flex;
    max-width: 100% !important;
    align-items: center;
  }

  .video-js.vjs-fullscreen video {
    border-radius: 0;
    max-width: 100% !important;
    width: 100% !important;
  }

  .video-js.vjs-fullscreen {
    & .player__top-overlay,
    & .player_reactions-wrapper {
      display: none;
    }
  }

  .chat__desktop-player-title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    padding: 15px 20px 40px 20px;
  }

  .chat__desktop-player-title-backdrop-top {
    width: 100% !important;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    opacity: 0.8;
    border-radius: 10px;
  }

  .chat__desktop-player-title-backdrop-bottom {
    width: 100% !important;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    opacity: 0.8;
    border-radius: 10px;
  }

  .vjs-poster {
    border-radius: 10px;
  }

  .c-player {
    border-radius: 10px;
  }

  .video-js.vjs-fullscreen video {
    height: auto !important;
  }

  .video-js .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    z-index: 10;

    display: flex;
    align-items: flex-end;
    height: 60px;
    background: linear-gradient(360deg, #171717 -55.17%, rgba(23, 23, 23, 0) 81.03%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vjs-progress-control {
    z-index: 10;
    top: 10px;
    left: 0;
    height: 10px;
    width: 100%;
    position: absolute;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 26px;
  }
  .video-js .vjs-slider {
    height: 4px;
  }

  .video-js .vjs-volume-panel {
    position: absolute;
    left: 40px;
    height: 40px;
  }

  .vjs-volume-horizontal {
    height: 30px;
    margin-top: 5px;
  }

  .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
  .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal,
  .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
    z-index: 9999;
    width: 75px;
  }

  .video-js .vjs-time-control {
    z-index: 10;
    position: absolute;
    right: 165px;
    height: 40px;
    font-size: 14px;
  }

  .vjs-playback-rate {
    z-index: 10;
    position: absolute;
    right: 105px;
    height: 34px;
  }

  .vjs-picture-in-picture-control {
    position: absolute;
    right: 35px;
    height: 40px;
  }

  .vjs-picture-in-picture-control > .vjs-icon-placeholder:before {
    font-size: 24px;
  }

  .video-js .vjs-fullscreen-control {
    position: absolute;
    right: 0;
    height: 45px;
  }

  .vjs-fullscreen-control .vjs-icon-placeholder:before {
    font-size: 30px;
  }

  .video-js .vjs-play-progress:before {
    z-index: 101;
  }

  .video-js .vjs-play-control,
  .video-js .vjs-mute-control {
    height: 40px;
  }

  .custom-title-fade-out {
    display: flex;
    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 1.5s, opacity 1.5s;
    -moz-transition: visibility 1.5s, opacity 1.5s;
    -ms-transition: visibility 1.5s, opacity 1.5s;
    -o-transition: visibility 1.5s, opacity 1.5s;
    transition: visibility 1.5s, opacity 1.5s;

    /* Wait a moment before fading out the control bar */
    -webkit-transition-delay: 2s;
    -moz-transition-delay: 2s;
    -ms-transition-delay: 2s;
    -o-transition-delay: 2s;
    transition-delay: 2s;
  }
}
