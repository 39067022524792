.chat__post-owner-text:not(.file-thumbnail) {
  & * {
    color: #fff !important;
    background-color: transparent !important;
  }

  & a {
    color: #fff !important;
    background-color: transparent !important;
    text-decoration: underline;
    word-break: break-all;
  }

  .removed-video-comment-post__icon {
    background-color: rgba(114, 177, 255, 1) !important;
  }

  .file-thumbnail {
    background-color: #fff !important;
    svg {
      fill: rgb(36, 131, 247) !important;
    }
  }

  .reply-post-parent {
    font-size: 15px;
    line-height: 1.5;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }
}

.chat__post-partner-text:not(.file-thumbnail) {
  & * {
    color: rgb(23, 23, 23) !important;
    background-color: transparent !important;
  }
  & a {
    color: rgb(23, 23, 23) !important;
    background-color: transparent !important;
    text-decoration: underline;
    word-break: break-all;
  }

  .removed-video-comment-post__icon {
    background-color: rgba(188, 188, 195, 0.85) !important;
  }

  .file-thumbnail {
    background-color: #fff !important;
    svg {
      fill: rgb(36, 131, 247) !important;
    }
  }

  .reply-post-parent {
    font-size: 15px;
    line-height: 1.5;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;

    //&:before {
    //  content: '';
    //  position: absolute;
    //  bottom: 0;
    //  left: 0;
    //  right: 0;
    //  height: 1em;
    //  background: linear-gradient(to bottom, transparent, #f8f9fb);
    //}
  }
}
