.partner-sidebar__wrapper {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 3px;
    visibility: hidden;
  }
}
